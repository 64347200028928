<template>
    <div>

        <div class="my_treat_page">
            <div class="name_info">
                <div class="name_num">
                    <span class="left">{{detailinfo.name}}</span>
                </div>
                <div class="account_info">
                    <div class="sex_age_phone">
                        <span>{{detailinfo.sex}} . {{detailinfo.age}}岁</span>
                        <span v-if="detailinfo.phone">{{detailinfo.phone}}</span>
                    </div>
                    <div class="account">
                        <span>工号：{{dispname}}</span>
                    </div>
                </div>

            </div>
            <div class="habitus_info">
                <div class="info_line">
                    <span>拍摄医生</span>
                    <p class="context_p">{{detailinfo.doctor  || '--'}}</p>
                </div>
                <div class="info_line">
                    <span>拍摄时间</span>
                    <p class="context_p">{{detailinfo.shoottime}}</p>
                </div>
                <div class="info_line">
                    <span>报告医生</span>
                    <p class="context_p">{{detailinfo.reportdoctor  || '--'}}</p>
                </div>
                <div class="info_line">
                    <span>报告时间</span>
                    <p class="context_p">{{detailinfo.reporttime || '--'}}</p>
                </div>
                <div class="info_line">
                    <span>报告内容</span>
                    <p class="context" v-html="detailinfo.reportcontext || '<span>---</span>'"></p>
                </div>
            </div>

            <van-grid :border="false" :column-num="2" class="img_list">
                <van-grid-item v-for="(item, i) in detailinfo.imgs" :key="item.attachid">
                    <img v-lazy="item.url" @click="onChange(i + 1)" />
                    <!--                <van-image v-lazy="item.url" lazy-load />-->
                </van-grid-item>
            </van-grid>


            <div class="ctrl_bottom">
                <button @click="back">返回</button>
                <button class="exec_class" @click="download(detailinfo.reporturl)" v-if="detailinfo.reporturl" >下载报告</button>
            </div>

        </div>

        <van-image-preview v-model="show" :images="images" @change="onChange">
            <template v-slot:index>第{{ index }}张</template>
        </van-image-preview>
    </div>

</template>

<script>
    import Vue from 'vue';
    import { wxinfraredreportqry } from '@/api/medical'
    import { mapGetters } from 'vuex'
    import { Toast } from 'vant'
    import { Lazyload } from 'vant'

    // 注册时可以配置额外的选项
    Vue.use(Lazyload, {
        lazyComponent: true,
        loading: "https://yz.iyzzy.com/icon/loading.gif",
        error:"https://yz.iyzzy.com/icon/load_error.png"
    });

    export default {
        computed: {
            ...mapGetters([
                'username','dispname'
            ]),
        },
        data() {
            return {
                show: false,
                index: 0,
                images: [],
                serviceid: '',
                detailinfo: {}
            }
        },
        mounted () {
            this.$store.dispatch('hideOrShowNav', false)
            this.serviceid = this.$route.query.serviceid || ''
            this.getDetailInfo()
        },
        methods: {
            // 获取订单信息
            getDetailInfo(){
                Toast.loading({
                    message: '加载中...',
                    forbidClick: true,
                })
                let data = {
                    username: this.username,
                    serviceid: this.serviceid
                }
                wxinfraredreportqry(data).then(response => {
                    Toast.clear()
                    this.detailinfo = response.response_body
                    if(response.response_body.imgs && response.response_body.imgs.length > 0) {
                        response.response_body.imgs.forEach(img => {
                            this.images.push(img.url)
                        })
                    }
                    console.log(this.images, '--------images')
                })
            },
            //返回
            back() {
                this.$router.go(-1)
            },
            //下载报告
            download(url) {
                window.open(url, "_blank");
            },
            onChange(index) {
                this.show = true
                this.index = index;
            },
        },
    }
</script>

<style lang="scss" scoped>
    .img_list img {
        width: 100%;
    }
</style>